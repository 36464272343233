import React,{useEffect,useState} from "react";

import { Modal , Form, Button, InputGroup,Row,Col } from '@themesberg/react-bootstrap';

import http from "../../../http";

import   {toast}  from 'react-toastify';

export default (props) => {

    const [UserDetails,setUserDetails] = useState({});
    const [AddUser,setAddUser] = useState(false);
    const [getUsers,setUsers] = useState([]);
    const [getProducts,setProducts] = useState([]);
    const [getSellers,setSellers] = useState([]);
    const [getUpdateModal,setUpdateModal] = useState();
    const [getOrderId,setOrderId] = useState(null);


    


    useEffect(() => {

       console.log('Props',props)
      //  fetchUsers();
      fetchOrderDetails(props);
    }, [props]);

    useEffect(() => {
      // fetchUsers();
      // fetchProducts();
      // fetchSellers();
    },[])

    const fetchOrderDetails = (data) => {
      const OrderDetails =  data.UpdatedOrder;
      setOrderId(OrderDetails.id)

      const payload = {
        title:OrderDetails.title,
        price:OrderDetails.price,
        offer_price:OrderDetails.offer_price,
        duration:OrderDetails.duration,
        description :OrderDetails.description
      }

      setUserDetails(payload);

      console.log('OrderDetails',OrderDetails)
    }





    const handleinputchange = (e) => {
      setUserDetails({...UserDetails,[e.target.name]:e.target.value})
    }

  
    const handleSubmit = (event) => {
      event.preventDefault()

      console.log('AddOrder',UserDetails);
      http.post('admin/package/update',
        {
          id:getOrderId,
          title:UserDetails.title,
          price:UserDetails.price,
          offer_price:UserDetails.offer_price,
          duration:UserDetails.duration,
          description :UserDetails.description
      })
        .then((res) => {

          console.log('Add',res)
            toast.success('Package upated successfully')
            props.OnHide(false);
            props.OnfetchOrders();
        
        })
        .catch((e) => {
          console.log('e',e)
          toast.error('something goes wrong');
        })
  
  
    }


  return (
    <Modal centered show={props.show} onHide={()=> props.OnHide(false)}>
        <Modal.Header className="border-0"  closeButton>
          <Modal.Title> Update Package</Modal.Title>

        </Modal.Header>
        <Form onSubmit={handleSubmit} >
        <Modal.Body className="border-top"  >
            <Row className="gy-3" >
            <Col xl="12">
            <Form.Label   htmlFor="customername">Title </Form.Label>
            <InputGroup >
              <Form.Control  type="text" onChange={handleinputchange} value={UserDetails.title}  name="title" required autoFocus  placeholder="Enter title" />
            </InputGroup>
            </Col>
            <Col xl="12">
            <Form.Label   htmlFor="customername">Price</Form.Label>
            <InputGroup >
              <Form.Control  type="number" onChange={handleinputchange} value={UserDetails.price}  name="price" required autoFocus  placeholder="Enter price" />
            </InputGroup>
            </Col>
            <Col xl="12">
            <Form.Label   htmlFor="customername">Duration (In Days)</Form.Label>
            <InputGroup >
              <Form.Control  type="number" onChange={handleinputchange} value={UserDetails.duration}  name="duration" required autoFocus  placeholder="Enter  duration of package in Days" />
            </InputGroup>
            </Col>
            <Col xl="12">
            <Form.Label   htmlFor="customername">Offer Price</Form.Label>
            <InputGroup >
              <Form.Control  type="number" onChange={handleinputchange} value={UserDetails.offer_price}  name="offer_price" required autoFocus  placeholder="Enter offer price" />
            </InputGroup>
            </Col>
            <Col xl="12">
            <Form.Label   htmlFor="customername">Description</Form.Label>
            <InputGroup >
              <textarea className="form-control"  type="number" onChange={handleinputchange} value={UserDetails.description}  name="description" required autoFocus  placeholder="Enter description" />
            </InputGroup>
            </Col>
           


            </Row>       
        </Modal.Body>
        <Modal.Footer  className="d-flex justify-content-end border-0" >

              <h6 onClick={()=> props.OnHide(false)} className="text-danger mx-4 fw-bolder" type="button"  >Close </h6>
              <Button size="md" variant="dark" type="submit" >Update Package </Button>

        </Modal.Footer>
        </Form>
      </Modal>
  );
};
