import React,{useEffect,useState} from "react";
import { Col, Row,Tab,Tabs, Card, Table } from '@themesberg/react-bootstrap';
import LeftArrow from '../../assets/Icons/LeftArrow.svg'
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import http from "../../http";
import ProfileImagePlaceholder from '../../assets/Placeholders/Profile_avatar_placeholder_large.png';
import { ORDER_PAGE_TILLE } from "../../constants/Orders";
import OrderStatusBar from "./components /OrderStatusBar";


export default (props) => {

    const [getStylist, setStylist] = useState({});
    const [getUser, setUser] = useState([]);
    const [isloading,setLoading] = useState(false);
    const [UserId,setUserId] = useState(props.match.params.id);
    const [key, setKey] = useState('products');
    const [getBackpage,setBackpage] = useState();
  
    useEffect(() => {
        fetchStylistDetails();
        console.log('props.match.params.id',props.match.params.id)
        const queryParams = new URLSearchParams(props.location.search)
        const _backPage = queryParams.get("backpage")
        setBackpage(_backPage)
    }, []);

    const fetchStylistDetails  = async () => {
        const Params = {personalStylistId:UserId,adminUserId:localStorage.getItem('userId')};
        const Users = await http.get('/get/StylistDetails',{params:Params})
        console.log('User',UserId);

        if(Users.data.status == 'success'){
            setStylist(Users.data.data.stylistDetails)
            setUser(Users.data.data.users)
            setLoading(false);
            console.log('User',Users.data.data);
        }

    }
    const TableRow = (props) => {
      const { userId,name, profilePicUrl,isProfileCreated, gender,lastActive ,createdOn, updatedOn } = props;
    
  
      return (
        <tr>

          <td>
          <Link to={`/user/${userId}`} >
            <span className="fw-normal">
              <div className="row" >
                  <div className="col-1" >
                      <img  className="user-avatar md-avatar rounded-circle" 
                      onError={(e) => { console.log('Errror')  } }  
                      src={(profilePicUrl) ? profilePicUrl : ProfileImagePlaceholder } ></img>
                  </div>
                  <div className="col-auto " >
                     <span className="px-4 text-dark" >{(name) ? name  : 'N/A' }
                     <br></br>
                     <span className="px-4 text-muted" > {lastActive}</span>
                     </span>
                 
                  </div>
              </div>
            </span>
            </Link> 
          </td>
          
          <td>
            <span className="fw-normal text-capitalize h6">
            {(gender) ? gender  : 'N/A' }

            </span>
          </td>
          <td>
            <span  className={`fw-normal text-capitalize h6 text-${(isProfileCreated) ? 'success' : 'danger'}`}  >
            {(isProfileCreated) ? 'Live'  : 'Pending' }

            </span>
          </td>
         
          
        </tr>
      );
    };

    const UserTable = (props) => {
      return (
      <Table hover className="user-table align-items-center">
      <thead>
        <tr>
           <th className="border-bottom"> Name</th>
            <th className="border-bottom">Gender</th>
          <th className="border-bottom">Status</th>
         </tr>
       </thead>
       <tbody>
         {getUser.map(u => <TableRow key={`users-${u.userId}`} {...u} />)}
        </tbody>
    </Table>
    )}


    

   
  return (
    <>
 { (isloading) ? <div  className="ReactLoader d-flex pt-12 justify-content-center" > 
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
       </div> :  ''}

     <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ">
          <div className="start">
              <h4> <Link to={`/orders?page=${getBackpage}`} > <img className="BackArrow" src={LeftArrow} />  </Link> {ORDER_PAGE_TILLE}</h4>
          </div>  
          <div className="end d-inline-flex" >
          <div className="TopButton">
              </div>
               <Navbar title="Overview"  />
          </div>  
      </div>
      <div className="table-settings mb-4">
      <Card  className="table-wrapper table-responsive  border-0">
      <Card.Body className="pt-0">
       
  <OrderStatusBar />
      </Card.Body>
       </Card>
      </div>
    </>
  );
};
