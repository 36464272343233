import React,{useEffect,useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnchor, faEllipsisV,faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { Col, Row,Modal , Form, Button, InputGroup ,Nav, Card, Table, Dropdown, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';

import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { CounterWidgetV3 } from "../../components/Widgets";
import http from "../../http";
import   {toast}  from 'react-toastify';
import OrderStrings from './../../constants/Orders/index'
import AddOrder from "./components /AddOrder";

import moment from "moment-timezone";
import UpdateOrder from "./components /UpdateOrder";
import Roles from "../../constants/Roles";




export default (props) => {

    const queryParams = new URLSearchParams(props.location.search)
    const pageNumber = queryParams.get("page")
    const [getUsers, setUsers] = useState([]);
    const [TotalCount, SetTotalCount] = useState(0);
    const [page,setPage] = useState((pageNumber == null || pageNumber == 'null') ? 1 : pageNumber);
    const [TotalPages ,setTotalPage] = useState(Array(6).fill(0));
    const [UserDetails,setUserDetails] = useState({email:'',name:'',gender:'male',number:''});
    const [AddOrderShow,setAddOrderShow] = useState(false);
    const [UpdateOrderShow,setUpdateOrderShow] = useState(false);
    const [AllSelect,setAllSelect] = useState(false);
    const [SelectedRows,SetSelectedRow] = useState([]);
    const [DeleteModal,setDeleteModal] = useState(false);
    const [isloading,setLoading] = useState(false);
    const [limit,setlimit] =useState(10);
    const [getUpdatedOrder,setUpdatedOrder] =useState({});



    


    useEffect(() => {
       fetchOrders();
       SetSelectedRow([])
       
       
    }, [page]);



    const fetchOrders  = async () => {
        const Packages = await http.get('/admin/feedback')
        if(Packages.data.Success){
             let Data = Packages.data.data;
             console.log('Packages',Data); 
             setUsers(Data);
        }

    }



    const handleSingleDelete =  (e,id) => {
        setDeleteModal(true);
        SetSelectedRow([id])
    }

    const _handlesubmitDelete = async (id) => {
      const Delete = await http.post('admin/package/delete',{ id:SelectedRows[0]})
        await fetchOrders();
        toast.success('Deleted successfully')
        setDeleteModal(false);

    }

    const handleUpdateOrder = (e,OrderDetails) => {
        setUpdateOrderShow(true);
        setUpdatedOrder(OrderDetails);
    }





    const TableRow = (props) => {
        const {id,name,email,phone_number,subject,feedback,createdAt,role } = props;
        return (
          <tr>
          <td><span className="fw-normal text-capitalize small"> {id}</span></td>
          <td><span className="fw-normal text-capitalize small"> 
          <p className="small" >  Name :{name}</p>
          <p className="small" >  Email :{email}</p>
          <p className="small" >  Contact Number :{phone_number}</p>
          </span></td>
          <td><span className="fw-normal text-capitalize small"> {Roles[role]}</span></td>
          <td><span className="fw-normal text-capitalize small">{subject}</span></td>
          <td><span className="fw-normal text-capitalize small"> {feedback}</span></td>
          <td><span className="fw-normal text-capitalize small">
             <p className="small" >  Date :{moment(createdAt).format('DD-MM-Y')}</p>
             <p className="small" >  Time :{moment(createdAt).format('h:m:ss A')}</p>
           
             
              </span></td>
        

          </tr>
        );
      };




  return (

    <>
    { (isloading) ? <div  className="ReactLoader d-flex pt-12 justify-content-center" > 
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
       </div> :  ''}
       <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ">
          <div className="start">
              <h4>  Packages </h4>
          </div>  
          <div className="end d-inline-flex" >
              <div className="TopButton">
              
              </div>
               <Navbar title="Overview"  />
          </div>  
      </div>
   
      <div className="table-settings ">

        <Row  className="my-4 justify-content-end"> 
          <Col  xs={12} className="d-flex justify-content-end" >
            
            {/* <button onClick={()=> setAddOrderShow(true)} className="btn btn-dark" > Add Package </button> */}
            </Col>
        
        </Row> 
      </div>


      
      <Card border="light">
        <Card.Body>
        <Table hover className="user-table table-striped align-items-center mt-2 table-responsive">
          <thead>
            <tr  className="table-dark">
              <th className="border-bottom"> ID</th>
              <th className="border-bottom">User </th>
              <th className="border-bottom">Role </th>
              <th className="border-bottom">Subject </th>
               <th className="border-bottom">Feedback </th>
               <th className="border-bottom">Time </th>

            </tr>
          </thead>
        
          <tbody>
            {getUsers.map(p => <TableRow key={`stylist-${p.id}`} {...p} />)}

          </tbody>

        </Table>
        
        
        </Card.Body>
      </Card>


       
      < AddOrder  OnfetchOrders={() => fetchOrders()}  OnHide={()=>setAddOrderShow(false)} show={AddOrderShow} />
      < UpdateOrder  UpdatedOrder={getUpdatedOrder}   OnfetchOrders={() => fetchOrders()}  OnHide={()=>setUpdateOrderShow(false)} show={UpdateOrderShow} />

      <Modal  centered show={DeleteModal} onHide={()=> setDeleteModal(false)}>
        <div closeButton>
        </div>

        <Modal.Body className="justify-content-center text-center my-3">
       
           <p>Are you sure to delete this product from the app</p>

         <div className="row justify-content-center mt-4" >

        <Button onClick={e => _handlesubmitDelete(e) }  variant="danger" className="col-5 mx-1" type="button" >Delete it</Button>
        <Button onClick={()=> setDeleteModal(false)} variant="outline-dark" className="col-5 mx-1" type="button" > Cancel</Button>

        </div>  
                

        </Modal.Body>
       
      </Modal>
     
     

     

  

    </>
  );
};
