import axios from "axios";

let axios_request = axios.create({
  baseURL: `https://dev20.invitocreatives.com/api/protected/`,
// baseURL: `http://localhost:3030/api/protected/`,
  timeout: 10000,
});

const requestHandler = (request) => {
  request.headers.Authorization = "Bearer " + localStorage.getItem("api_token");

  return request;
};

const responseHandler = (response) => {
  console.log('http',response.status);
  if (response.status === 401 ) {
    window.alert("Invalid Request");
    window.localStorage.clear();
  }

  return response;
};

function manageErrorConnection(err) {

  const originalConfig = err.config;
  if (err.response && err.response.status == 401) {
    alert(err.response.data.message);
    localStorage.setItem("login", false);
    window.location.reload();
    localStorage.setItem("api_token", "");
    window.localStorage.clear();
    return Promise.reject(err);
  }
  // else if (err.response && err.response.status == 404 && !originalConfig._retry) {
  //   originalConfig._retry = true;

  //   return axios_request(originalConfig);
  // }
  else if (err.response && err.response.status == 403) {
    localStorage.setItem("token", false);
    localStorage.setItem("api_token", "");
    alert("Session Expired");
    window.location.reload();
    window.localStorage.clear();
    return Promise.reject(err);
  } else {
    window.localStorage.clear();
    return Promise.reject(err);
  }
}

axios_request.interceptors.request.use(
  (request) => requestHandler(request),
  manageErrorConnection
);

axios_request.interceptors.response.use(
  (response) => responseHandler(response),
  manageErrorConnection
);

export default axios_request;
