import React,{useEffect,useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Modal , Form, Button, InputGroup , Card } from '@themesberg/react-bootstrap';


import LeftArrow from '../../assets/Icons/LeftArrow.svg'
import Plus from '../../assets/Icons/Plus.svg'

import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import http from "../../http";
import   {toast}  from 'react-toastify';
import { useHistory } from 'react-router';
import ProfileImagePlaceholder from '../../assets/Placeholders/Profile_avatar_placeholder_large.png'

import Select from 'react-select'
import Api from "../../http/Api";
import seasonsJson from "../../data/seasons";
import S3 from 'react-aws-s3';
import { AddProductLink,ProductsLink,EditProductLink }  from './../../constants';

import { Slide,Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
const S3_BUCKET ='stylistadminassets';
const REGION ='ap-south-1';
const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: 'AKIASWRBYD6UYQMTC4IV',
  secretAccessKey: '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'
}

const ReactS3Client = new S3(config);



export default () => {

    const history = useHistory()
    const [getCategories, setCategories] = useState([]);
    const [getSizes, setSizes] = useState([]);
    const [getSeasons, setSeasons] = useState([]);
    const [getStaticBrands, setStaticBrands] = useState([]);
    const [getColors, setColors] = useState([]);
    const [getProduct, setProduct] = useState({range:0 });
    const [getImages,setImages] = useState([]);   
    const [UserDetails,setUserDetails] = useState({email:'',name:'',gender:''});
    const [AddUser,setAddUser] = useState(false);
    const [getVendors,setVendors] = useState([]);
    const [DeleteModal,setDeleteModal] = useState(false);

    

    useEffect(() => {
        // gefetchRequiredData();
        fetchVendors();
    }, []);


    const fetchVendors =  async () => {
      const Config = await http.get('/admin/configs')
      console.log('Users',Config);
      setProduct({range:Config.data.data.nearbyrange})
      

    }


    const gefetchRequiredData = async () => {
      const Categories = await Api.get('/getCategories');
      const StaticBrands = await Api.get('/getBrands');
      setCategories(Categories.data.data.data)
      setStaticBrands(StaticBrands.data.data.data)
      const Colors = await Api.get('/getColors');
      // console.log('Colors',Colors.data.data.data)
      setColors(Colors.data.data.data)
      const Seasons = seasonsJson;

      setSeasons(Seasons);
      const SizesA = await Api.get('/getSizes');
      const Sizes = SizesA.data.data.data.map(value => ({value:value.sizeName,label:value.sizeName}));
      setSizes(Sizes);
    }

    const uploadFile =  async (e) => {
      let file = e.target.files[0];
        let filename = file.name;
        let NewFilename = `${(Math.random() * 1000000000000000000 )}-${(Math.random() * 1000000000000000000 )}.${filename.split('.').pop()}`
        ReactS3Client.uploadFile(file, NewFilename)
                      .then((data) => {setImages([...getImages,data.location])}).catch(err => console.error(err))

    }
    const handleinputchange = async (e) => {
      console.log(e.target.value)
      switch (e.target.name) {
          case 'seasons':
            if(e.target.checked){
              setProduct({...getProduct,seasons:[...getProduct.seasons,e.target.value]})
            }else{
              let CurrentSeasons =  [...getProduct.seasons];
              const index = CurrentSeasons.indexOf(e.target.value);
              await CurrentSeasons.splice(index, 1);
              setProduct({...getProduct,seasons:CurrentSeasons})
            }
          break;
        case 'Category':
        let Values =  JSON.parse(e.target.value);
        setProduct({...getProduct,[e.target.name]:Values})
        break;

        case 'StaticBrand':
          setProduct({...getProduct,[e.target.name]:JSON.parse(e.target.value)})
          break;
         
      
        default:
          setProduct({...getProduct,[e.target.name]:e.target.value})
        break;
      }
    }

    const handleSelectChange = (value) => {
      setProduct({...getProduct,sizes:value})
    }

    const handleSubmit = (event) => {
      event.preventDefault()
      http.post('/admin/config/update',{
        nearbyrange:getProduct.range
      })
        .then((res) => {
          if(res.data.Success){
            toast.success('Great, Config Updated ')
          }else{
            toast.error(res.data.data.statusMessage)
          }
        })
        .catch((e) => {
          toast.error(e.response.data.message)
        })
  
  
    }

    const handlePasswordSubmit = (event) => {
        event.preventDefault()
        http.post('/admin/update',{
          old_password:getProduct.old_password,
          new_password:getProduct.new_password
        })
          .then((res) => {
            if(res.data.Success){
                setProduct({...getProduct,old_password:'',new_password:'',confirm_password:''})
              toast.success('Great, Admin password is updated ')
            }else{
              toast.error(res.data.message)
            }
          })
          .catch((e) => {
            toast.error(e.response.data.message)
          })
    
    
      }
  








  return (
    <>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ">
          <div className="start">
              <h4> Admin Settings </h4>
               <small>Customize preferences, manage account details, and personalize notifications in Settings Page
                </small> 
          </div>  
          <div className="end d-inline-flex" >
              <div className="TopButton">
              </div>
               <Navbar title="Overview"  />
          </div>  
      </div>
    
      <Card  className="table-wrapper table-responsive border-0 shadow-sm mt-3">
        <Card.Header>

        </Card.Header>
      <Card.Body className="pt-2">
        <div className="row " >
            <div className="col-lg-6 col-12" >
                <div className="bg-light-gray" >
                <form onSubmit={handleSubmit} >
                <h6> Config Details </h6>
                    <hr></hr>
                <div className="form-group mt-3">
                    <label className="form-label-1" htmlFor="exampleInputEmail1">Near By Range</label>
                    <br></br>
                    <input style={{width:'-webkit-fill-available'}} type="range" required  value={getProduct.range} name="range" onChange={handleinputchange} className=""  min="0.5" max="50" step="0.5"  placeholder="Enter stock Here" />
                    <div className="d-flex justify-content-between" >
                        <small>Min:0.5 Km</small>
                        <small>Max:50 Km</small>
                    </div>
                    <div className="d-flex justify-content-start" >
                        <p>Range : {getProduct.range} Km</p>
                    </div>

                    <small  className="form-text text-muted"></small>
              </div>
              <div className="form-group mt-3 d-flex justify-content-end">
                     <button type="submit" className="btn btn-secondary   text-white">Save</button>
              </div>

                
              
                </form>
            </div>

             </div>
             <div className="col-lg-6 col-12" >
                <div className="bg-light-gray" >
                <form onSubmit={handlePasswordSubmit} >
                    <h6> Update Admin Details </h6>
                    <hr></hr>
                <div className="form-group">
                    <label className="form-label-1" htmlFor="exampleInputEmail1">Old Password</label>
                    <input required onChange={handleinputchange}  value={getProduct.old_password} className="input-group border-1 " type="password" name="old_password" />
                    <small  className="form-text text-muted"></small>
              </div>
              <div className="form-group">
                    <label className="form-label-1" htmlFor="exampleInputEmail1">New Password</label>
                    <input required onChange={handleinputchange}  value={getProduct.new_password}  className="input-group border-1" type="password" name="new_password" />
                    <small  className="form-text text-muted"></small>
              </div>
              <div className="form-group">
                    <label className="form-label-1" htmlFor="exampleInputEmail1">New Password Confirm</label>
                    <input required onChange={handleinputchange}  value={getProduct.confirm_password}  className="input-group border-1" type="password" name="confirm_password" />
                    <small  className="form-text text-muted text-danger">
                        {(getProduct.new_password == getProduct.confirm_password) ? '' : "Confirm Passwords don't match with new password" }
                    </small>
              </div>
              <div className="form-group mt-3 d-flex justify-content-end">
                     <button disabled={(getProduct.new_password != getProduct.confirm_password)}  type="submit" className="btn btn-secondary   text-white">Save</button>
              </div>

                
              
                </form>
            </div>

             </div>
          

        </div>
      </Card.Body>
    </Card>

  

    </>
  );
};
