import React,{useEffect,useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Modal , Form, Button, InputGroup , Card } from '@themesberg/react-bootstrap';


import LeftArrow from '../../assets/Icons/LeftArrow.svg'
import Plus from '../../assets/Icons/Plus.svg'

import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import http from "../../http";
import   {toast}  from 'react-toastify';
import { useHistory } from 'react-router';
import ProfileImagePlaceholder from '../../assets/Placeholders/Profile_avatar_placeholder_large.png'

import Select from 'react-select'
import Api from "../../http/Api";
import seasonsJson from "../../data/seasons";
import S3 from 'react-aws-s3';
import { AddProductLink,ProductsLink,EditProductLink }  from '../../constants';

import { Slide,Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
const S3_BUCKET ='stylistadminassets';
const REGION ='ap-south-1';
const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: 'AKIASWRBYD6UYQMTC4IV',
  secretAccessKey: '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'
}

const ReactS3Client = new S3(config);



export default (props) => {

    const history = useHistory()
    const [getCategories, setCategories] = useState([]);
    const [getSizes, setSizes] = useState([]);
    const [getSeasons, setSeasons] = useState([]);
    const [getStaticBrands, setStaticBrands] = useState([]);
    const [getColors, setColors] = useState([]);
    const [getProduct, setProduct] = useState({name:'',description:'',price:'',colors:'',Category:'',seasons:[],season:'',button_link:'',sizes:[],StaticBrand:{} });
    const [getImages,setImages] = useState([]);   
    const [UserDetails,setUserDetails] = useState({email:'',name:'',gender:''});
    const [AddUser,setAddUser] = useState(false);
    const [DeleteModal,setDeleteModal] = useState(false);
    const [UserId,setUserId] = useState(props.match.params.vandorid);
    const [ValidUser,setValidUser] = useState(true);;

    

    useEffect(() => {
        gefetchRequiredData();
        // gefetchBrand()
    }, []);



    const gefetchBrand = async () => {
      const Params = {brandId:UserId};
      const Users = await http.get('/get/brandProducts',{params:Params}).then((res)=> {
        console.log('aa',res)
        setValidUser(true)
      }).catch((res) => {
        console.log('aaaa',res)
        setValidUser(false)

        toast.error('Invalid Id')
      })
     
    }


    const gefetchRequiredData = async () => {
      const Categories = await Api.get('/getCategories');
      const StaticBrands = await Api.get('/getBrands');
      setCategories(Categories.data.data.data)
      setStaticBrands(StaticBrands.data.data.data)
      const Colors = await Api.get('/getColors');
      // console.log('Colors',Colors.data.data.data)
      setColors(Colors.data.data.data)
      const Seasons = seasonsJson;

      setSeasons(Seasons);
      const SizesA = await Api.get('/getSizes');
      const Sizes = SizesA.data.data.data.map(value => ({value:value.sizeName,label:value.sizeName}));
      setSizes(Sizes);
    }

    const uploadFile =  async (e) => {
      let file = e.target.files[0];
        let filename = file.name;
        let NewFilename = `${(Math.random() * 1000000000000000000 )}-${(Math.random() * 1000000000000000000 )}.${filename.split('.').pop()}`
        ReactS3Client.uploadFile(file, NewFilename)
                      .then((data) => {setImages([...getImages,data.location])}).catch(err => console.error(err))

    }
    const handleinputchange = async (e) => {
      console.log(e.target.value)
      switch (e.target.name) {
          case 'seasons':
            if(e.target.checked){
              setProduct({...getProduct,seasons:[...getProduct.seasons,e.target.value]})
            }else{
              let CurrentSeasons =  [...getProduct.seasons];
              const index = CurrentSeasons.indexOf(e.target.value);
              await CurrentSeasons.splice(index, 1);
              setProduct({...getProduct,seasons:CurrentSeasons})
            }
          break;
        case 'Category':
        let Values =  JSON.parse(e.target.value);
        setProduct({...getProduct,[e.target.name]:Values})
        break;

        case 'StaticBrand':
          setProduct({...getProduct,[e.target.name]:JSON.parse(e.target.value)})
          break;
         
      
        default:
          setProduct({...getProduct,[e.target.name]:e.target.value})
        break;
      }
    }

    const handleSelectChange = (value) => {
      setProduct({...getProduct,sizes:value})
    }

    const handleSubmit = (event) => {
      event.preventDefault()
      http.post('/add/product',{
        brandId: "63c99077c57ec68c609c072f",
        staticBrandId: getProduct.StaticBrand.brandId,
        productName: getProduct.name,
        productPrice: getProduct.price,
        productDescription: getProduct.description,
        productColor: getProduct.colors,
        imageUrls: getImages,
        categoryId: getProduct.Category.categoryId,
        categoryName: getProduct.Category.categoryName,
        subCategoryId: getProduct.Category.subCategoryId,
        subCategoryName: getProduct.Category.subCategoryName,
        seasons: getProduct.seasons,
        productSizes: getProduct.sizes.map((s) => s.value),
        productButtonLink: getProduct.button_link
      })
        .then((res) => {
          if(res.data.data.statusCode === 200){
            toast.success('Great, New Product Added Add In')
            // history.push(ProductsLink);
            setProduct({name:'',description:'',price:'',colors:'',Category:'',seasons:[],season:'',button_link:'',sizes:[],StaticBrand:{} })
            setImages([])
          }else{
            toast.error(res.data.data.statusMessage)
          }
        })
        .catch((e) => {
          toast.error(e.response.data.message)
          console.log('r',e.response)
        })
  
  
    }

    const _handleRemoveImage = async (e,image) => {

      let Images = [...getImages];

      const index = Images.indexOf(image);
      await Images.splice(index, 1);
      setImages(Images)


    }








  return (
    <>
     {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ">
          <div className="start">
            
            <h4><Link to={ProductsLink} >  <img className="BackArrow" src={LeftArrow} />    </Link>  Add Product </h4>
          </div>  
          <div className="end d-inline-flex" >
                <div className="TopButton">

                </div>
               <Navbar title="Overview"  />
          </div>  
      </div> */}
    
     {ValidUser?  <Card  className="table-wrapper table-responsive shadow-sm p-4">
        <div  >
          <h3 className="text-title-dark" >Add New Product</h3>
        </div>
        <div className="row " >
            <div className="col-12" >
              <div className="bg-light-gray" >

            <form onSubmit={handleSubmit} >
              <div className="form-group">
                <label className="form-label-1" htmlFor="exampleInputEmail1">Name of the product</label>
                <input type="text" required value={getProduct.name} name="name" onChange={handleinputchange} className="form-control form-input-1"  placeholder="Enter your product name" />
                <small  className="form-text text-muted"></small>
              </div>
              <div className="form-group mt-3">
                <label className="form-label-1" htmlFor="exampleInputEmail1">Price ($)</label>
                <input type="number" required  value={getProduct.price} name="price" onChange={handleinputchange} className="form-control form-input-1"  placeholder="Enter Price Here" />
                <small  className="form-text text-muted"></small>
              </div>
              <div className="form-group mt-3">
                <label className="form-label-1" htmlFor="exampleInputEmail1">Description of the product</label>
                <textarea rows={5} required name="description" value={getProduct.description}  onChange={handleinputchange} className="form-control "  placeholder="Description of the product" />
                <small  className="form-text text-muted"></small>
              </div>
              <div className="form-group mt-3">
                <label className="form-label-1" htmlFor="exampleInputEmail1">Color</label>
                <select placeholder="Please select color" required name="colors"  onChange={handleinputchange}  className="form-input-1" >
                <option> Please Select Color </option>
                  {getColors?.map(c => <option value={c.colorName} > {c.colorName} </option>)}
                </select>
                {/* <input type="email" className="form-control form-input-1"  placeholder="Enter email" /> */}
                <small  className="form-text text-muted"></small>
              </div>
              <div className="form-group mt-3">
                <label className="form-label-1" htmlFor="exampleInputEmail1">images</label>  
                          
                  <div className="row " >
                     
                      {getImages.map(image => <div className="  image-preview col-5 col-lg-2 " > <small onClick={(e)=> _handleRemoveImage(e,image)} className="text-danger"> <FontAwesomeIcon icon={faTrash} /> </small>  <img height='109px' className="img-responsive"  src={image}></img>  </div>)}
                  </div>
                  <div className="Input-file mb-5 " >
                        <input type="file" id="files"  accept="image/png, image/jpeg"  onChange={uploadFile} className="hide-input"  />
                          <div className="upload-box" > <img className="BackArrow icon" src={Plus} /></div>
                      </div>   


              </div>
              <div className="form-group mt-3">
                <label className="form-label-1" htmlFor="exampleInputEmail1">Brand</label>
                <select placeholder="Please select brand" required name="StaticBrand"  onChange={handleinputchange}  className="form-input-1" >
                <option> Please Select brand </option>
                  {getStaticBrands?.map(b => <option value={`{"brandId":"${b.brandId}","brandName":"${b.brandName}"}`} > {b.brandName} </option>)}
                </select>
                {/* <input type="email" className="form-control form-input-1"  placeholder="Enter email" /> */}
                <small  className="form-text text-muted"></small>
              </div>
              
              <div className="form-group mt-3">
                <label className="form-label-1" htmlFor="exampleInputEmail1">Category</label>
               
                <select  required name="Category" onChange={handleinputchange}  className="form-input-1" >
                  <option> Please Select Category </option>
{getCategories?.map(o => <optgroup key={o.categoryId} label={o.categoryName} > {o.subCategory.map(s =>  <option  value={`{"categoryName":"${o.categoryName}","categoryId":"${o.categoryId}","subCategoryName":"${s.subCategoryName}","subCategoryId":"${s.subCategoryId}"}`}>  {o.categoryName}  -   {s.subCategoryName}</option>)}  </optgroup>)}  
                </select>
              </div>
              <div className="form-group mt-3">
                <label className="form-label-1" htmlFor="exampleInputEmail1">Seasons</label>
                <br></br>
               
               {getSeasons.map(s => <> <input type="checkbox" checked={getProduct.seasons.includes(s.name)}  name="seasons" value={s.name} onChange={handleinputchange} class="btn-check" id={`btn-check-${s.id}`} autocomplete="off" />
                <label class={`btn  form-check-1  ${(getProduct.seasons.includes(s.name)) ? 'active' :'' }`} for={`btn-check-${s.id}`} > {s.name} </label></>)}

                <small  className="form-text text-muted"></small>
              </div>
              <div className="form-group mt-3">
                <label className="form-label-1" htmlFor="exampleInputEmail1">Sizes</label>
               <Select placeholder="Please select size" onChange={handleSelectChange} name='sizes' isMulti options={getSizes} ></Select>
              
              </div>
              <div className="form-group mt-3">
                <label className="form-label-1" htmlFor="exampleInputEmail1">Button Link</label>
                <input  name="button_link" required  value={getProduct.button_link}  onChange={handleinputchange} type="url" className="form-control form-input-1"  placeholder="For Examples. https://www.net-a-porter.com/en-in/shop/product/veronica-beard/clothing/blazers/miller-dickey-double-breasted-stretch-crepe-blazer/1647597289333933" />
                <small  className="form-text text-muted"></small>
              </div>

             
              <button type="submit" className="btn btn-primary btn-lg mt-4">Add Product</button>
            </form>
            </div>

            </div>


        </div>
      
      <Card.Body className="pt-0">
      

      </Card.Body>
    </Card> :  <h3 className="text-danger text-center my-6" > Invalid ID </h3>}

 


    </>
  );
};
