
import React , {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye,faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';


import BgImage from "../../assets/img/illustrations/signin.svg";
import SiteLogo from '../../assets/images/logo.png'
import http from "../../http";
import Api from "../../http/Api";

import   {toast}  from 'react-toastify';
import { useHistory } from 'react-router';
export default () => {
  let history = useHistory();

  const [ShowPassword,setShowpassword] = useState('password');

  const [UserDetails,setUserDetails] = useState({email:'',password:''});




  const handleinputchange = (e) => {
    setUserDetails({...UserDetails,[e.target.name]:e.target.value})
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    Api.post('admin/login',{email:UserDetails.email,password:UserDetails.password})
      .then((res) => {

        if(res.data.Success){
          console.log(res.data)
          localStorage.setItem('EmailId', res.data.payload.email)
          localStorage.setItem('userId', 1)
          localStorage.setItem('role', 'admin')
          localStorage.setItem('api_token', res.data.Token)
          localStorage.setItem('login', true)

          history.push("/dashboard/overview");
          toast.success('Great, your are logged in successfully ')

        }else{
          toast.error(res.data.message)
        }
      })
      .catch((e) => {
        console.log(e.response.data.message)
        toast.error(e.response.data.message)
      })


  }



  const handleShowPassword  = () => {
    // toast.success("Wow so easy!")
  if(ShowPassword == 'password'){
    setShowpassword('text');
  }else{
    setShowpassword('password');
  }

  }





  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-6">
        <Container fluid >
        
          <Row className="justify-content-center form-bg-image" >
            <Col xs={12} lg={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h1>Dining App</h1>
                  {/* <img  src={SiteLogo} className="" /> */}
                  <h6 className="mb-0">Log In to Dining</h6>
                <small>Enter your email and password below</small>
                </div>
                <Form onSubmit={handleSubmit} className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label> Email</Form.Label>
                    <InputGroup>
                      <Form.Control required value={UserDetails.email} onChange={handleinputchange} name="email" autoFocus  type="email" placeholder="example@company.com" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label> Password</Form.Label>
                      <InputGroup>
                        <Form.Control  value={UserDetails.password}  onChange={handleinputchange} name="password" className="password" required type={ShowPassword} placeholder="Password" />
                        <InputGroup.Text onClick={handleShowPassword}  >
                          <FontAwesomeIcon icon={(ShowPassword == 'password') ? faEyeSlash : faEye } />
                        </InputGroup.Text>
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      
                      {/* <Card.Link className="small text-end"></Card.Link> */}
                      <Link to={'forgot-password'} > 
                      {/* <Card.Link className="small text-end text-dark">Lost password?</Card.Link> */}
                      </Link>
                    </div>
                  </Form.Group>
                  {/* <Card.Link as={Link} to={Routes.DashboardOverview.path} className="text-gray-700"> */}
                  <Button variant="primary" type="submit" className="w-100">
                    Sign in
                  </Button>
              {/* </Card.Link> */}
                
                </Form>

              
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
