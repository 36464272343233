
import React , {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye,faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';


import BgImage from "../../assets/img/illustrations/signin.svg";
import SiteLogo from '../../assets/images/logo.png'
import http from "../../http";
import Api from "../../http/Api";

import   {toast}  from 'react-toastify';
import { useHistory } from 'react-router';
export default () => {
  let history = useHistory();

  const [ShowPassword,setShowpassword] = useState('password');
  const [userType,setUserType] = useState(null);
  const [isUserExist,setUserExist] = useState(false);

  const [UserDetails,setUserDetails] = useState({email:'',password:'',otp:''});
  const [user,setUser] = useState(null);




  const handleinputchange = (e) => {
    setUserDetails({...UserDetails,[e.target.name]:e.target.value})
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    Api.post('user/login',{phone_number:UserDetails.phone})
      .then((res) => {

       if(res.data.Success){
        if(res.data.data.Role == 'New User'){
          toast.error('User Not Found in our system')
        }else{
          setUserType(res.data.data.Role)
          setUserExist(true);
          toast.success('OTP sent on your phone number| Please confirm')
        }
       }else{
        toast.error('User Not Found in our system')
       }
      })
      .catch((e) => {
        console.log(e.response.data.message)
        toast.error(e.response.data.message)
      })


  }

  const handleSubmitOTP = (event) => {
    event.preventDefault()
    Api.post('user/login/otp/verify',{phone_number:UserDetails.phone,otp:UserDetails.otp,type:userType})
      .then((res) => {

        if(res.data.Success){
          console.log('res.data.',res.data.data)
          setUser(res.data.data);
          toast.success('OTP verify successfully');
        }else{
          // setUserType(res.data.data.Role)
          // setUserExist(true);
          toast.error('Invalid OTP')
        }
      })
      .catch((e) => {
        console.log(e.response.data.message)
        toast.error(e.response.data.message)
      })


  }

  const handleSubmitDeleteAccount = (event) => {
    event.preventDefault()
    Api.post('protected/user/delete', null, {
      headers: { 
        'Authorization': `Bearer ${user.AccessToken}`
      }
    })
      .then((res) => {

        if(res.data.Success){
          console.log('res.data.',res.data.data)
          setUserType(null)
          setUserExist(false);
          setUser(null);
          toast.success('Your Account Delete Successfully');
        }else{
          toast.error('Error ')
          setUserType(null)
          setUserExist(false);
          setUser(null);
          setUserDetails({})
        }
        setUserDetails({})
      })
      .catch((e) => {
        console.log(e.response.data.message)
        toast.error(e.response.data.message)
      })


  }


  const handleShowPassword  = () => {
    // toast.success("Wow so easy!")
  if(ShowPassword == 'password'){
    setShowpassword('text');
  }else{
    setShowpassword('password');
  }

  }





  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-6">
        <Container fluid >
        
          <Row className="justify-content-center form-bg-image" >
            <Col xs={12} lg={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h1>Dining App</h1>
                  {/* <img  src={SiteLogo} className="" /> */}


                  <h6 className="mb-0">Delete Your Account On Dining </h6>
                {/* <small>Enter Your Phone Number </small> */}
                </div>
                  {(userType == null) ?
                    <Form onSubmit={handleSubmit} className="mt-4">
                      <Form.Group id="email" className="mb-4">
                        <Form.Label> Enter Your Phone Number</Form.Label>
                        <InputGroup>
                          <Form.Control required value={UserDetails.phone} onChange={handleinputchange} name="phone" autoFocus  type="text" placeholder="Enter your phone number here : Eg.+1234567890" />
                        </InputGroup>
                      </Form.Group>
                      <Button variant="primary" type="submit" className="w-100">
                        Find Your Account
                      </Button>
                    </Form>
                  :
                    <>
                    {(user) ? <>
                    
                      <Form onSubmit={handleSubmitDeleteAccount} className="mt-4">
                        <Form.Group id="email" className="mb-4">
                          <Form.Label> Do you to delete your account</Form.Label>
                          <InputGroup>
                           <Card style={{ width: '100%' }}>
                             <Card.Body>
                               <Card.Title>Account Details</Card.Title>
                               <Card.Text>
                                 <h6> Name: <strong>{user.User.firstName}   {user.User.lastName}</strong></h6> 
                                 <h6> Phone Number: <strong>{user.User.phone_number}</strong>  </h6>
                                 <h6> Email: <strong>{(user.User.email) ? user.User.email :  'N/A'}</strong>  </h6>
                                 <h6> Date OF Birth: <strong>{user.User.dateOfBirth}</strong>  </h6>
                                 <h6> Name On Card: <strong>{user.User.nameOnCard}</strong>  </h6>
                               </Card.Text>
                             </Card.Body>
                           </Card>
                          </InputGroup>
                        </Form.Group>
                        <Button variant="danger" type="submit" className="w-100">
                          Delete My Account
                        </Button>
                      </Form>
                    
                    </> : <>

                    <Form onSubmit={handleSubmitOTP} className="mt-4">
                        <Form.Group id="email" className="mb-4">
                          <Form.Label> Enter OTP Here</Form.Label>
                          <InputGroup>
                            <Form.Control required value={UserDetails.otp} onChange={handleinputchange} name="otp" autoFocus  type="text" placeholder="Enter Four Digit OTP here" />

                          </InputGroup>
                        </Form.Group>
                        <Button variant="primary" type="submit" className="w-100">
                          Submit OTP
                        </Button>
                      </Form>
                    </>}
                     
                    </> 
                  }
              


              
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
