import React,{useEffect,useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnchor, faEllipsisV,faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { Col, Row,Modal , Form, Button, InputGroup ,Nav, Card, Table, Dropdown, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';

import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import http from "../../http";
import   {toast}  from 'react-toastify';



export default (props) => {

    const queryParams = new URLSearchParams(props.location.search)
    const pageNumber = queryParams.get("page")
    const [getUsers, setUsers] = useState([]);
    const [page,setPage] = useState((pageNumber == null || pageNumber == 'null') ? 1 : pageNumber);
    const [TotalPages ,setTotalPage] = useState(Array(6).fill(0));
    const [UserDetails,setUserDetails] = useState({email:'',name:'',gender:'male',number:''});

    const [UpdateOrderShow,setUpdateOrderShow] = useState(false);
    const [AllSelect,setAllSelect] = useState(false);
    const [SelectedRows,SetSelectedRow] = useState([]);
    const [DeleteModal,setDeleteModal] = useState(false);
    const [isloading,setLoading] = useState(false);
    const [getUpdatedOrder,setUpdatedOrder] =useState({});



    


    useEffect(() => {
       fetchOrders();
       SetSelectedRow([])
       
       
    }, [page]);



    const fetchOrders  = async () => {
        const Restaurants = await http.get('/admin/restaurants')
        if(Restaurants.data.Success){
             let Data = Restaurants.data.data;
             console.log('Restaurants',Data); 
             setUsers(Data);
        }

    }




    const handleinputchange = (e) => {
      setUserDetails({...UserDetails,[e.target.name]:e.target.value})
    }

  
    
    const handleAllSelect = (e) => {

     
      setAllSelect(e.target.checked);

      if(e.target.checked){
        let AllUserIDs = getUsers.map((u) => u.brandId);
        SetSelectedRow(AllUserIDs);
      }else{
        SetSelectedRow([]);
      }

     
    }  
    
    
    const handleSelectRow = async (e,id) => {

      if(e.target.checked){

        SetSelectedRow([...SelectedRows,id]);
      }else{


        let _SelectedRows = SelectedRows;


        const index = _SelectedRows.indexOf(id);
        console.log(index)
        if (index > -1) { // only splice array when item is found
           await _SelectedRows.splice(index, 1);
          console.log('ki',_SelectedRows)

          SetSelectedRow([..._SelectedRows]);

        }
      
      }


    }


    const handleSingleDelete =  (e,id) => {
        setDeleteModal(true);
        SetSelectedRow([id])
    }

    const _handlesubmitDelete = async (id) => {
      const Delete = await http.post('admin/restaurant/delete',{ id:SelectedRows[0]})
        await fetchOrders();

        toast.success('Restaurant Deleted successfully')
        setDeleteModal(false);

    }

    const handleUpdateOrder = (e,OrderDetails) => {
        setUpdateOrderShow(true);
        setUpdatedOrder(OrderDetails);
    }





    const TableRow = (props) => {
        const {id,name,Images,email,tagLine,costForOne,Cuisines,address,craetedAt,updatedAt } = props;
        return (
          <tr>
          <td><span className="fw-normal text-capitalize small"> {id}</span></td>
          <td>

          <span className="fw-normal  h6 row">
            <div className="col-4 " >
              {(Images.length) ? <img width={'100px'} className="img-responsive" src={Images[0].imageSrc}></img> : ''}
            </div>
            <div className="col-8" >
                <h6><strong>{(name.length > 15) ? name.substring(0,15) +  ' . . .' : name }</strong></h6>
                <small className="" ><strong>{(tagLine.length > 20) ? tagLine.substring(0,20) +  ' . . .' : tagLine }</strong></small>
                <br/>
                <span className="fw-normal small"><strong>{email}</strong></span>
            </div>
           </span>
           </td>

          <td><span className="fw-normal text-capitalize ">
            {Cuisines.map((cuisine) => { return <p key={`cuisine-${cuisine.id}`} className="badge badge-dark bg-dark text-light m-1 h6" >{cuisine.name}</p> })}</span>
            {(Cuisines.length) ? '' : 'N/A'}
            </td>

          <td><span className="fw-normal text-capitalize small">$ {costForOne}</span></td>
        

            <td>
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle as={Button} split variant="link" className="text-muted m-0 p-0">
                  <span className="icon icon-sm">
                    <FontAwesomeIcon icon={faEllipsisV} className="icon-dark" />
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item >
                  <Link to={`/restaurant/${id}/update`}>
                     Update 
                  </Link>
                  </Dropdown.Item>
                  <Dropdown.Item  onClick={(e) => handleSingleDelete(e,id)} className="text-danger">
                     Delete 
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        );
      };




  return (

    <>
    { (isloading) ? <div  className="ReactLoader d-flex pt-12 justify-content-center" > 
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
       </div> :  ''}
       <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ">
          <div className="start">
              <h4>  Restaurants </h4>
          </div>  
          <div className="end d-inline-flex" >
              <div className="TopButton">
              
              </div>
               <Navbar title="Overview"  />
          </div>  
      </div>
   
      <div className="table-settings ">

        <Row  className="my-4 justify-content-end"> 
          <Col  xs={12} className="d-flex justify-content-end" >
            
            <Link to={'/restaurant/create'}  className="btn btn-dark" > Add Restaurant </Link>
            </Col>
        
        </Row> 
      </div>


      
      <Card border="light">
        <Card.Body>
        <Table hover className="user-table table-striped align-items-center mt-2 table-responsive">
          <thead>
            <tr  className="table-dark">
              <th className="border-bottom"> ID</th>
              <th className="border-bottom"> Restaurant </th>
              <th className="border-bottom">Cuisines</th>
              <th className="border-bottom">Cost For One($) </th>

              <th className="border-bottom">Action</th>
            </tr>
          </thead>
        
          <tbody>
            {getUsers.map(p => <TableRow key={`stylist-${p.id}`} {...p} />)}

          </tbody>

        </Table>
        
        
        </Card.Body>
      </Card>


       
      {/* < AddOrder  OnfetchOrders={() => fetchOrders()}  OnHide={()=>setAddOrderShow(false)} show={AddOrderShow} /> */}
      {/* < UpdateOrder  UpdatedOrder={getUpdatedOrder}   OnfetchOrders={() => fetchOrders()}  OnHide={()=>setUpdateOrderShow(false)} show={UpdateOrderShow} /> */}

      <Modal  centered show={DeleteModal} onHide={()=> setDeleteModal(false)}>
        <div closeButton>
        </div>

        <Modal.Body className="justify-content-center text-center my-3">
       
           <p>Are you sure to delete this product from the app</p>

         <div className="row justify-content-center mt-4" >

        <Button onClick={e => _handlesubmitDelete(e) }  variant="danger" className="col-5 mx-1" type="button" >Delete it</Button>
        <Button onClick={()=> setDeleteModal(false)} variant="outline-dark" className="col-5 mx-1" type="button" > Cancel</Button>

        </div>  
                

        </Modal.Body>
       
      </Modal>
     
     

     

  

    </>
  );
};
