import React,{useEffect,useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Modal , Form, Button, InputGroup , Card } from '@themesberg/react-bootstrap';
import Autocomplete from "react-google-autocomplete";

import { useParams } from 'react-router-dom';


import LeftArrow from '../../assets/Icons/LeftArrow.svg'
import Plus from '../../assets/Icons/Plus.svg'

import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import http from "../../http";
import   {toast}  from 'react-toastify';
import { useHistory } from 'react-router';
import ProfileImagePlaceholder from '../../assets/Placeholders/Profile_avatar_placeholder_large.png'

import Select from 'react-select'
import Api from "../../http/Api";
import seasonsJson from "../../data/seasons";
import S3 from 'react-aws-s3';

import { Map, GoogleApiWrapper } from 'react-google-maps';
import { Slide,Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import MapContainer from "./components /MapContainer";
const S3_BUCKET ='diningapp01';
const REGION ='us-west-2';
const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: 'AKIAWMQBRPCBEG4AXCQ4',
  secretAccessKey: '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'
}





const ReactS3Client = new S3(config);



export default () => {

    const history = useHistory()
 
    const [getCuisines, setCuisines] = useState([]);
    const [getProduct, setProduct] = useState({name:'',tagline:'',email:'',password:'',costforone:'',Cuisine:[]});
    const [getAddress, setAddress] = useState({address:'',lat:0,lng:0});
    const [getRestaurant, setRestaurant] = useState({});
    const [getImages,setImages] = useState([]);   
    const id = useParams().id;
    useEffect(() => {
        fetchRestaurant();
        gefetchRequiredData();
    }, []);


    const fetchRestaurant = async () => {
      const res = await http.get(`/admin/restaurant/details`,{params:{id:id}});
      if(res.status === 200){
        let Payload = res.data.data;

        let imageData = Payload.Images.map(image => image.imageSrc)

        setProduct({name:Payload.name,tagline:Payload.tagLine,email:Payload.email,password:'',costforone:Payload.costForOne,Cuisine:Payload.Cuisines.map(cuisine => ({ label: cuisine.name, value: cuisine.id }))})
        setAddress({address:Payload.address,lat:Payload.latitude,lng:Payload.longitude})
        setImages(imageData)
      }

    }



   

    const gefetchRequiredData = async () => {

      const cuisines = await http.get('/admin/cuisines');
      const Sizes = cuisines.data.data.map(value => ({value:value.id,label:value.name}));
      setCuisines(Sizes);

    }

    const uploadFile =  async (e) => {
      let file = e.target.files[0];
        let filename = file.name;
        let NewFilename = `${(Math.random() * 1000000000000000000 )}-${(Math.random() * 1000000000000000000 )}.${filename.split('.').pop()}`
        ReactS3Client.uploadFile(file, NewFilename)
                      .then((data) => {setImages([...getImages,data.location])}).catch(err => console.error(err))

    }


    const handleinputchange = async (e) => {

      switch (e.target.name) {
          case 'seasons':
            if(e.target.checked){
              setProduct({...getProduct,seasons:[...getProduct.seasons,e.target.value]})
            }else{
              let CurrentSeasons =  [...getProduct.seasons];
              const index = CurrentSeasons.indexOf(e.target.value);
              await CurrentSeasons.splice(index, 1);
              setProduct({...getProduct,seasons:CurrentSeasons})
            }
          break;
        case 'Category':
        let Values =  JSON.parse(e.target.value);
        setProduct({...getProduct,[e.target.name]:Values})
        break;

        case 'StaticBrand':
          setProduct({...getProduct,[e.target.name]:JSON.parse(e.target.value)})
          break;
         
      
        default:
          setProduct({...getProduct,[e.target.name]:e.target.value})
        break;
      }
    }

    const handleSelectChange = (value) => {
      console.log(value)
      setProduct({...getProduct,Cuisine:value})
    }

    const handleSubmit = (event) => {
      event.preventDefault()


      const Payload ={ 
        "id":id,
        "email":getProduct.email,
        "name": getProduct.name,
        "tagline" :getProduct.tagline,
        "address":getAddress.address,
        "location" :{
            "latitude":getAddress.lat,
            "longitude":getAddress.lng
        },
        "costforone":getProduct.costforone,
        "images" : getImages,
        "cuisine":getProduct.Cuisine.map((value) => {return value.value})
      }

      if(getProduct.password !== ''){
        Payload['password'] = getProduct.password
      }

     
      console.log('Payload',Payload);

      http.post('admin/restaurant/update',Payload)
        .then((res) => {
          if(res.data.Success){
            toast.success('Great, Restaurant Updated')
            history.push('/restaurants');
          }else{

            toast.error(res.data.message)
          }
        })
        .catch((e) => {
          console.log('res.data.data.statusMessage',e.response.data)

          // toast.error(e.response.data)
        })
  
  
    }

    const _handleRemoveImage = async (e,image) => {

      let Images = [...getImages];

      const index = Images.indexOf(image);
      await Images.splice(index, 1);
      setImages(Images)


    }


    const _handleLocation = async (e,value)=> {

      console.log(e,value);
       const {formatted_address,geometry} = e;
       
        let lat = geometry.location.lat()
        let lng = geometry.location.lng()

        setAddress({lat:lat,lng:lng,address:formatted_address})

    }







  return (
    <>
     <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ">
          <div className="start">
            <h4><Link to='/restaurants' >  <img className="BackArrow" src={LeftArrow} />    </Link>  Update Restaurant </h4>
          </div>  
          <div className="end d-inline-flex" >
                <div className="TopButton">

                </div>
               <Navbar title="Overview"  />
          </div>  
      </div>
    
      <Card  className="table-wrapper table-responsive shadow-sm p-1">
       
        <form onSubmit={handleSubmit} >
        <div className="row " >
            <div className="col-lg-10 " >
              <div className="bg-light-gray row" >
              <div className="form-group col-6">
                <label className="form-label-1" htmlFor="exampleInputEmail1">Name of the Restaurant</label>
                <input type="text" required value={getProduct.name} name="name" onChange={handleinputchange} className="form-control "  placeholder="Enter your Restaurant name" />
                <small  className="form-text text-muted"></small>
              </div>
              <div className="form-group col-6">
                <label className="form-label-1" htmlFor="exampleInputEmail1">Tagline </label>
                <input  required name="tagline" value={getProduct.tagline}  onChange={handleinputchange} className="form-control "  placeholder="enter tagline of your restaurant" />
                <small  className="form-text text-muted"></small>
              </div>
              
              <div className="form-group col-6">
                <label className="form-label-1" htmlFor="exampleInputEmail1">email</label>
                <input type="email" required  value={getProduct.email} name="email" onChange={handleinputchange} className="form-control "  placeholder="Enter email Here" />
                <small  className="form-text text-muted"></small>
              </div>
              <div className="form-group  col-6">
                <label className="form-label-1" htmlFor="exampleInputEmail1">Password</label>
                <input type="password"   value={getProduct.password} name="password" onChange={handleinputchange} className="form-control "  placeholder="Enter password for your restaurant" />
                <small  className="form-text text-muted"></small>
              </div>
             
              <div className="form-group col-6">
                <label className="form-label-1" htmlFor="exampleInputEmail1">Address  </label>
                     <Autocomplete
                        defaultValue={getAddress.address}
                        required
                        options={{types: ['establishment','geocode'],}}
                        className="form-control"
                        apiKey={'AIzaSyAsNp6rPCXV5UMYyfBCfA27xfeswahJMvM'}
                        onPlaceSelected={((e) => { _handleLocation(e,getProduct)})}
                        />
                <small  className="form-text text-muted"></small>
              </div>
              
              
              <div className="form-group col-6">
                <label className="form-label-1" htmlFor="exampleInputEmail1">Cuisine</label>
                <Select  placeholder="Please select size" onChange={handleSelectChange} name='Cuisine' isMulti options={getCuisines} 
                value={getProduct.Cuisine}></Select>
                <small  className="form-text text-muted"></small>
              </div>
              <div className="form-group col-6">
                  <label className="form-label-1" htmlFor="costforone">Cost for One</label>
                  <input type="number" required name="costforone" value={getProduct.costforone} onChange={handleinputchange} className="form-control" placeholder="Enter cost for one" />
                </div>
              
              
              <div className="form-group col-12">
                <label className="form-label-1" htmlFor="exampleInputEmail1">images</label>                
                  <div className="row" >
                      <div clas sName="col-2 Input-file" >
                        <input type="file" id="files"  accept="image/png, image/jpeg"  onChange={uploadFile} className="hide-input"  />
                          <div className="upload-box" > <img className="BackArrow icon" src={Plus} /></div>
                      </div>
                      {getImages.map(image => <div key={`image-${image}`} className="col-auto image-preview" > <small onClick={(e)=> _handleRemoveImage(e,image)} className="text-danger"> <FontAwesomeIcon icon={faTrash} /> </small>  <img height='109px' width='149px' src={image}></img>  </div>)}
                  </div>


              </div>

             
       

              <div className="col-3" >
              <button type="submit" className="btn btn-info  mt-4">Update Restaurant</button>

              </div>
           
            </div>

            </div>
            <div className="col-lg-6" >
            {/* <MapContainer/> */}
            </div>
            
          

        </div>
        </form>
    
    </Card>

   

     

    </>
  );
};
