const months = [];

for (let i = 0; i < 12; i++) {
  const date = new Date(2000, i, 1);
  const month = date.toLocaleString('default', { month: 'long' });
  // const year = date.getFullYear();
  const monthYear = `${month}`;
  months.push(monthYear);
}

export default months;