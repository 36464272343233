
import React, {useState}  from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import http from "../../http";
import   {toast}  from 'react-toastify';
import { useHistory } from 'react-router';

export default () => {

  let history = useHistory();
  const [UserDetails,setUserDetails] = useState({email:''});

  const handleinputchange = (e) => {
    setUserDetails({...UserDetails,[e.target.name]:e.target.value})
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    http.post('/forgetPassword',{emailId:UserDetails.email})
      .then((res) => {
        if(res.data.data.statusCode === 200){
          toast.success('Great, your new password sent on your mail. Please check ')
          history.push('/');
        }else{
          toast.error(res.data.data.statusMessage)
        }
      })
      .catch((e) => {
        
      })


  }



 
  return (
    <main>
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <Container fluid >
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to sign in
            </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500 ">
             
                <h3>Forgot your password?</h3>
                <p className="mb-4">Don't fret! Just type in your email and we will send you a code to reset your password!</p>
                <Form onSubmit={handleSubmit} >
                  <div className="mb-4">
                    <Form.Label htmlFor="email">Your Email</Form.Label>
                    <InputGroup id="email">
                      <Form.Control onChange={handleinputchange} value={UserDetails.email}  name="email" required autoFocus type="email" placeholder="john@company.com" />
                    </InputGroup>
                  </div>
                  <Button variant="primary" type="submit" className="w-100">
                    Recover password
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
