
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import {  Nav, Modal, Form, Image, Navbar, Dropdown, Container, InputGroup,Button } from '@themesberg/react-bootstrap';

import NOTIFICATIONS_DATA from "../data/notifications";
import Profile3 from "../assets/images/ImagePlaceHolder.png";

import   {toast}  from 'react-toastify';
import { useHistory } from 'react-router';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import http from "../http";

export default (props) => {
  const [notifications, setNotifications] = useState(NOTIFICATIONS_DATA);


  let history = useHistory();
  const [show, setShow] = useState(false);
  const [getNumbershow, setNumberShow] = useState(false);
  const [UserDetails,setUserDetails] = useState({number:localStorage.getItem('number')});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleUpdatNumberClose = () => setNumberShow(false);
  const handleUpdatNumberShow = () => setNumberShow(true);




  const LogoutUser =  () => {
    localStorage.removeItem('EmailId')
    localStorage.removeItem('userId')
    localStorage.removeItem('role')
    localStorage.removeItem('number')
    localStorage.setItem('login', false)
    history.push('/');
    toast.success('Your are logged out successfully ')
  }

  const handleinputchange = (e) => {
    setUserDetails({...UserDetails,[e.target.name]:e.target.value})
  }

  const handleUpdateSubmit = (event) => {
    event.preventDefault()
    http.post('/update/BrandUser',{
      "adminUserId": "63c99077c57ec68c609c072f",
      "brandEmail":  localStorage.getItem('EmailId'),
      "whatsappNumber" : UserDetails.number
    })
      .then((res) => {
        if(res.data.data.statusCode === 200){
          toast.success('Great,Vendor WhatsApp Number is now updated in app')
          localStorage.setItem('number',UserDetails.number)
          handleUpdatNumberClose();
        }else{
          toast.error(res.data.data.statusMessage)
        }
      })
      .catch((e) => {
        
      })


  }


  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <Nav className="align-items-center">
            {/* <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} >
              <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" />
                  {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                    Notifications
                  </Nav.Link>

                  {notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)}

                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown> */}

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block mx-3">
                    <span className="mb-0 font-small fw-bold text-capitalize"> 
                         {localStorage.getItem('EmailId').split('@')[0] }
                    </span>
                  </div>
                  <Image src={Profile3} className="user-avatar md-avatar rounded-circle" />
                 
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                {/* <Link to={localStorage.getItem('role') == 'admin' ? '/change-password'  : '/auth/change-password'} > */}

                {localStorage.getItem('role') != 'admin' ? 
                
                <Dropdown.Item className="fw-bold">
                <div onClick={handleUpdatNumberShow} >
                    <FontAwesomeIcon icon={faWhatsapp} className="text-dark me-2" /> 
                    
                    {(localStorage.getItem('number') == 'NA' || localStorage.getItem('number') == null  ) ? 'Add' : 'Update' } WhatsApp Number
                </div>
              </Dropdown.Item>
                : ''}
                



                <Dropdown.Item className="fw-bold" href={localStorage.getItem('role') == 'admin' ? '/#/change-password'  : '/#/auth/change-password'}  >
             

                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> Change Password
              
                </Dropdown.Item>
                {/* </Link> */}
                <Dropdown.Divider />

                <Dropdown.Item className="fw-bold">
                  <div onClick={handleShow} >
                      <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
        <Modal   aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>

          
        <div closeButton>
        </div>

        <Modal.Body className="justify-content-center text-center my-3">
       
           <p>Are you sure to logout from the app</p>

         <div className="row justify-content-center mt-4" >

        <Button onClick={LogoutUser} variant="danger" className="col-5 mx-1" type="button" > Yes, Logout </Button>
        <Button onClick={handleClose} variant="outline-dark" className="col-5 mx-1" type="button" > Cancel</Button>

        </div>  
                

        </Modal.Body>
         
  
        </Modal>



        <Modal   aria-labelledby="contained-modal-title-vcenter" centered show={getNumbershow} onHide={handleUpdatNumberClose}>          
            <div closeButton> </div>
            <Modal.Header closeButton>
            <h6>Update Whatsapp Number</h6>
            </Modal.Header>
            <Form onSubmit={handleUpdateSubmit} >
                <Modal.Body className="">
                  <div className="row justify-content-center " >
                    <div className="">
                      <Form.Label htmlFor="name">WhatsApp Number</Form.Label>
                      <InputGroup id="name">
                      <Form.Control onChange={handleinputchange} required min={1000000000} max={999999999999999} value={UserDetails.number}  name="number"  autoFocus type="number" placeholder="eg.  +91 9876543210" />
                      </InputGroup>
                    </div>
                  </div>  
                </Modal.Body>
                <Modal.Footer  className="d-flex justify-content-start border-0" >
                  <Button variant="primary" className="col-5 mx-1" type="submit" > Update</Button>
                </Modal.Footer>
            </Form>
         </Modal>


      </Container>
    </Navbar>
  );
};
