module.exports = Object.freeze({

    ORDER_PAGE_TILLE:'Orders', 
    NEW_ORDER:'Total Order', 
    PENDING_ORDER:'Pending Order', 
    DELIVERED_ORDER:'DELIVERED ORDER', 
    PICKUPS_ORDER:'PICKUPS ORDER', 
    CANCELLED_ORDER:'CANCELLED ORDER', 
 

})