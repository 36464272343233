
import React,{useEffect, useState} from "react";

import { Col, Card ,Row, Button } from '@themesberg/react-bootstrap';

import { CounterWidgetV2} from "../../components/Widgets";
import Navbar from "../../components/Navbar";
import http from "../../http";
import { Chart } from "react-google-charts";

import months from "../../data/months";
import moment from "moment-timezone";
import { driver } from "driver.js";
const driverObj = driver();


export const options = {
  title: "Popular Brands",
  chartArea: { width: "70%" },
  colors: ["#217AFF"],
  hAxis: {
    title: "Popular",
    minValue: 0,
  },
  vAxis: {
    title: "Brands",
  },
};

const Colors= ['#217AFF'];

export default () => {

  const [getCount,setCount] = useState({User:0,Brand:0,MaleUserCount:0,StylistCount:0,FemaleUserCount:0})
  const [isloading,setLoading] = useState(false);
  const [getPopularBrands,setPopularBrands] = useState([]);
  const [getBrands,setBrands] = useState([]);
  const [Expand,setExpand] = useState(false);
  const [getBrand,setBrand] = useState('');
  const [getMonth,setMonth] = useState(moment().month() +1);
  const [getYear,setYear] = useState(moment().year()-1);

   const [getReferrals,setReferrals] = useState([]);
   const [getReferralsTotals,setReferralsTotals] = useState({TotalValue:'0',OverallValue:'0'});



  useEffect(() => {
    fetchDashboard();
    // fetchPopularBrands();
    // fetchBrands();
    const driverObj = driver({
      animate: true,
      showProgress: false,
      showButtons: ['next', 'previous', 'close'],
      steps: [
        { element: '.justify-content-start', popover: { title: 'Dashboard Stats', description: 'Here is the code example showing animated tour. Let\'s walk you through it.', side: "left", align: 'start' }},
        { element: '.col-5:nth-child(1)>div', popover: { title: 'Import the Library', description: 'It works the same in vanilla JavaScript as well as frameworks.', side: "bottom", align: 'start' }},
        { element: '.col-5:nth-child(2)', popover: { title: 'Importing CSS', description: 'Import the CSS which gives you the default styling for popover and overlay.', side: "bottom", align: 'start' }},
        { element: '.col-5:nth-child(3)', popover: { title: 'Create Driver', description: 'Simply call the driver function to create a driver.js instance', side: "left", align: 'start' }},
        { element: '.col-5:nth-child(4)', popover: { title: 'Start Tour', description: 'Call the drive method to start the tour and your tour will be started.', side: "top", align: 'start' }}
      ]
    });

    // driverObj.drive();
  }, []);


  useEffect(() => {
    // if( getBrand != ''){
    //   fecthProductReferrals(getBrand,getMonth);
    // }
  },[getBrand,getMonth])

  const fetchDashboard = async () => {
   let Request = await http.get('/admin/stats');
    let Payload =  {
      User:Request.data.data.UsersCount,
      Spouse:Request.data.data.SpouseCount,
      Restaurant:Request.data.data.RestaurantCount,
      Cards:Request.data.data.CardsCount,
    }
   setCount(Payload)
   setLoading(false)
  }

  const fetchBrands = async() => {
    let Brands = await http.get('getAllBrands',{params:{emailId:localStorage.getItem('EmailId')}});
    setBrands(Brands.data.data.brandsList);
    setBrand(Brands.data.data.brandsList[0].brandId)
  }


  const fecthProductReferrals = async(brand,month) => {
    let params = {params:{ year:moment().year(),month:month,brandId:brand}}
    let Referrals = await http.get('product/referrals',params);
    let Data = Referrals.data.data;
    setReferralsTotals({TotalValue:Data?.totalPrice,OverallValue:Data?.overallPrice})
    let ForData= [["Days", "Referral Value"]];
    await Data.result.map((value,index) => {
      ForData.push([value.day,value.productPrice ])
    })
    if(Data.result.length == 0){
      ForData.push([0,0])
    }
    setReferrals(ForData)
  }
  const handleSelectChange = (e) => {

    switch (e.target.name) {
      case 'month':
        setMonth(e.target.value)
      break;
      case 'brand':
        setBrand(e.target.value)
      break;

    }

  }

  const fetchPopularBrands = async() => {
    let PopularBrands = await http.get('popular/brands');
    let Data = PopularBrands.data.data;

    let ForData = [["Brand", "Popular",{ role: "style" }]];
    await Data.map((value,index) => {
          ForData.push([value.brandName,value.weightage, Colors[0] ])
    }) 
    // console.log('ForData',ForData);
    setPopularBrands(ForData)
  }

  

  return (
    <>

{ (isloading) ? <div  className="ReactLoader d-flex pt-12 justify-content-center" > 
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
       </div> :  ''}
     
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center ">
          <div className="start">
              <h4>    Overview </h4>
          </div>  
          <div className="end d-inline-flex" >

               <Navbar title="Overview"  />
          </div>  
         
      </div>
      <div className="table-settings mb-4">
        <Row className="justify-content-start ">
          <Col id="usercount" xs={5} md={5} lg={3} xl={3}>
          <CounterWidgetV2 category="Total Users" title={getCount.User} />
          </Col>
          <Col  xs={5} md={5} lg={3} xl={3}>
          <CounterWidgetV2 category="Total Spouse" title={getCount.Spouse} />
          </Col>
          <Col xs={5} md={5} lg={3} xl={3}>
          <CounterWidgetV2 category="Issue Cards" title={getCount.Cards} />
          </Col>
          <Col xs={5} md={5} lg={3} xl={3}>
          <CounterWidgetV2 category="Total Restaurants" title={getCount.Restaurant} />
          </Col>
        
        </Row>
        {/* <Row className="justify-content-start my-4"  >
        <Col xs={5} md={5} lg={3} xl={3}>
          <CounterWidgetV2 category="Female Users" title={getCount.FemaleUserCount} />
          </Col>
        </Row> */}
      </div>

    

    </>
  );
};
