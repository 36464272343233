import React, { useState } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

const MapContainer = (props) => {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [address, setAddress] = useState('');
  const [getlocation,setlocation] = useState({lat:30.286081,long:436.993487})

  const onMarkerClick = (props, marker, e) => {
    const { position } = marker;
    console.log('marker',position)

    const lat = position.lat();
    const lng = position.lng();

    console.log('marker',{lat,lng})


    const geocoder = new props.google.maps.Geocoder();
    const latLngObj = new props.google.maps.LatLng(lat, lng);

    geocoder.geocode({ location: latLngObj }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          setSelectedPlace(marker);
          setAddress(results[0].formatted_address);
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  };

  const onMapClick = (props, marker, e) => {

    const { LatLng } = e;
    console.log('props',props)
    console.log('marker',marker)
    console.log('e',e.latLng)

    const lat = e.latLng.lat();
    const lng = e.latLng.lng();

    console.log('center',{lat,lng})
    setlocation({lat:lat,long:lng});


    


    // const geocoder = new props.google.maps.Geocoder();
    // const latLngObj = new props.google.maps.LatLng(lat, lng);

    // geocoder.geocode({ location: latLngObj }, (results, status) => {
    //   if (status === 'OK') {
    //     if (results[0]) {
    //       setSelectedPlace(marker);
    //       setAddress(results[0].formatted_address);
    //     } else {
    //       console.log('No results found');
    //     }
    //   } else {
    //     console.log('Geocoder failed due to: ' + status);
    //   }
    // });
  };

  return (
    <Map
    mapContainerStyle={{
      width: '100%',
      height: '85vh',
      }}
      google={props.google}
      zoom={14}
      initialCenter={{ lat:getlocation.lat, lng:getlocation.long }}
      onClick={onMapClick}
    >
      <Marker onClick={onMarkerClick} position={{lat:getlocation.lat, lng:getlocation.long}} />
      {/* Add other components or markers as needed */}
    </Map>
  );
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyAsNp6rPCXV5UMYyfBCfA27xfeswahJMvM',
})(MapContainer);
