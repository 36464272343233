import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

import DashboardOverview from "./dashboard/DashboardOverview";


import UsersIndex from './Users';
import SpouseIndex from './Spouse';
import UserView from './Users/view';
import SettingView from './Setting/index';


import DirectCreateProduct from './Products/Direct-create';
import DirectCreateProducStylist from './Products/Direct-create-Stylist';


import PackagesIndex from './Packages/index';
import FeedbackIndex from './Feedback/index';
import CuisinesIndex from './Cuisines/index';
import RestaurantsIndex from './Restaurants/index';
import RestaurantCreate from './Restaurants/create';
import RestaurantUpdate from './Restaurants/update';

import Signin from "./examples/Signin";

import DeleteUserAccount from "./examples/DeleteUserAccount";

import ForgotPassword from "./examples/ForgotPassword";

import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";

// documentation pages

// components
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { toast } from 'react-toastify';

import { useHistory } from 'react-router';


const RouteWithLoader = ({ component: Component, ...rest }) => {
  


  if(localStorage.getItem('login')){


    switch (localStorage.getItem('role')) {
      case 'admin':
        return (<Redirect to={Routes.DashboardOverview.path} ></Redirect>);
      break;
      case 'brand':
        return (<Redirect to={'/Dashboard'} ></Redirect>);
      break;
    
      default:
        return (<Route {...rest} render={props => ( <Component {...props} />) } />)
      break;
    }



  }else{

    return (<Route {...rest} render={props => ( <Component {...props} />) } />)
    
  }

  
  
  
  
  };






const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  let history = useHistory();
  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
      console.log('Route User',rest.user)
    

    if(localStorage.getItem('login')){

    }else{
      toast.error('Please login first')
      history.push('/')
      // return <Redirect to={'/'} />
    }
    return () => clearTimeout(timer);

  }, [rest]);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (((localStorage.getItem('role') == rest.user) && (localStorage.getItem('login') == 'true')  ) ? <Route {...rest} render={props => (
    <>
      {/* <Preloader show={loaded ? false : true} /> */}
      <Sidebar />

      <main className="content">
      
        <Component {...props}  />
        <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
      </main>
    </>
  )}
  /> :  <Redirect to={Routes.NotFound.path} ></Redirect> );
};

export default () => (
  <Switch>

    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={'/delete-user'} component={DeleteUserAccount} />
    <Route  exact path={'/direct-products/:vandorid'} component={DirectCreateProduct} />
    <Route  exact path={'/add-product-by-stylist'} component={DirectCreateProducStylist} />

    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
   
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} /> 



    <RouteWithSidebar user = 'admin' exact path={Routes.DashboardOverview.path} component={(DashboardOverview)} />
    <RouteWithSidebar user = 'admin' exact path={'/users'} component={UsersIndex} />
    <RouteWithSidebar user = 'admin' exact path={'/spouse'} component={SpouseIndex} />
    <RouteWithSidebar user = 'admin' exact path={'/user/:id'} component={UserView} />
    <RouteWithSidebar user = 'admin' exact path={'/setting'} component={SettingView} />

     <RouteWithSidebar user = 'admin' exact path={'/packages'} component={PackagesIndex} />
     <RouteWithSidebar user = 'admin' exact path={'/cuisines'} component={CuisinesIndex} />
     <RouteWithSidebar user = 'admin' exact path={'/restaurants'} component={RestaurantsIndex} />
     <RouteWithSidebar user = 'admin' exact path={'/restaurant/:id/update'} component={RestaurantUpdate} />
     <RouteWithSidebar user = 'admin' exact path={'/feedback'} component={FeedbackIndex} />
     <RouteWithSidebar user = 'admin' exact path={'/restaurant/create'} component={RestaurantCreate} />


    {/* <RouteWithSidebar user = 'admin' exact path={'/user/statistics/:id'} component={UserStatistics} /> */}
    {/* <RouteWithSidebar user = 'admin' exact path={'/videos'} component={AdminVideos} /> */}

    {/* <RouteWithSidebar user = 'admin' exact path={'/vendors'} component={BrandIndex} />
    <RouteWithSidebar user = 'admin' exact path={'/vendor/:id'} component={BrandView} /> */}

    {/* <RouteWithSidebar user = 'admin' exact path={'/orders'} component={OrdersIndex} />
    <RouteWithSidebar user = 'admin' exact path={'/order/view/:id'} component={OrdersView} />



    <RouteWithSidebar user = 'admin' exact path={'/stylist'} component={StylistIndex} />
    <RouteWithSidebar user = 'admin' exact path={'/stylist/:id'} component={StylistView} />
    
    <RouteWithSidebar user = 'admin' exact path={'/change-password'} component={ChangePassword} />

    <RouteWithSidebar user = 'admin'  exact path={'/admin-products'} component={ProductIndex} />
    <RouteWithSidebar user = 'admin'  exact path={'/admin-product/add'} component={CreateProductByAdmin} />
    <RouteWithSidebar user = 'admin'  exact path={'/admin-product/update/:id'} component={UpdateProduct} />


    <RouteWithSidebar user = 'brand' exact path={'/brand/order'} component={OrdersSellerIndex} />
    <RouteWithSidebar user = 'brand' exact path={'/brand/order/view/:id'} component={OrdersSellerView} />

    <RouteWithSidebar user = 'brand' exact path={'/Dashboard'} component={VendorDashboardOverview} />

    <RouteWithSidebar user = 'brand' exact path={'/brand/users'} component={VendorUsersIndex} />
    <RouteWithSidebar user = 'brand' exact path={'/brand/user/:id'} component={VendorUserView} />
    <RouteWithSidebar user = 'brand' exact path={'/brand/user/statistics/:id'} component={VendorUserStatistics} />

    <RouteWithSidebar user = 'brand' exact path={'/Store-Associate'} component={StoreAssociateIndex} />
    <RouteWithSidebar user = 'brand' exact path={'/Store-Associate/:id'} component={StoreAssociateView} />
    <RouteWithSidebar user = 'brand' exact path={'/auth/change-password'} component={ChangePassword} />
    <RouteWithSidebar user = 'brand'  exact path={'/products'} component={ProductAdminIndex} />
    <RouteWithSidebar user = 'brand'  exact path={'/product/add'} component={CreateProduct} />
    <RouteWithSidebar user = 'brand'  exact path={'/product/update/:id'} component={UpdateProduct} />
    <RouteWithSidebar user = 'brand' exact path={'/brand/videos'} component={Videos} /> */}

   
    
      

   
   <Route exact path={Routes.NotFound.path} component={NotFoundPage} />
  
   


    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
