


const AddProductLink = (localStorage.getItem('role') === 'admin') ? '/admin-product/add' : '/product/add';
const ProductsLink = (localStorage.getItem('role') === 'admin') ? '/admin-products' : '/products';
const EditProductLink = (localStorage.getItem('role') === 'admin') ? '/admin-product/update/' : '/product/update/';



export {
    AddProductLink,
    EditProductLink,
    ProductsLink
};