import React,{useEffect,useState} from "react";

import { Modal , Form, Button, InputGroup,Row,Col } from '@themesberg/react-bootstrap';

import http from "../../../http";
import   {toast}  from 'react-toastify';


export default (props) => {

    const [UserDetails,setUserDetails] = useState({email:'',name:'',gender:'male',number:''});
    const [AddUser,setAddUser] = useState(false);


    


    useEffect(() => {

       console.log('Props',props)
       
    }, [props]);



  




    const handleinputchange = (e) => {
      setUserDetails({...UserDetails,[e.target.name]:e.target.value})
    }

  
    const handleSubmit = (event) => {
      event.preventDefault()
      http.post('/addBrandUser',{
        "adminEmailId": "katedh.stylist@gmail.com",
        "brandEmailId": UserDetails.email,
        "brandName" : UserDetails.name,
        "whatsappNumber" : UserDetails.number ? UserDetails.number : 'NA'
      })
        .then((res) => {
          if(res.data.data.statusCode === 200){
            toast.success('Great,Vendor invited in app')
            //  fetchStylis();
              setAddUser(false);
              setUserDetails({email:'',name:''})
          }else{
            toast.error(res.data.data.statusMessage)
          }
        })
        .catch((e) => {
          
        })
  
  
    }


  return (
   <>
    <div class="row px-3">
            <div class="col">
                <ul id="progressbar" >
                    <li class="step0 active " id="step1">PLACED</li>
                    <li class="step0 active text-center" id="step2">SHIPPED</li>
                    <li class="step0  text-muted text-right" id="step3">DELIVERED</li>
                    <li class="step0  text-muted text-right" id="step4">DELIVERED</li>
                    <li class="step0  text-muted text-right" id="step5">DELIVERED</li>
                </ul>
            </div>
    </div>
       
    5
   </>
  );
};
