import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Modal, Form, Button, InputGroup, Card } from '@themesberg/react-bootstrap';
import Autocomplete from "react-google-autocomplete";
import { Link, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import Select from 'react-select';
import S3 from 'react-aws-s3';

import LeftArrow from '../../assets/Icons/LeftArrow.svg';
import Plus from '../../assets/Icons/Plus.svg';
import Navbar from "../../components/Navbar";
import http from "../../http";

const S3_BUCKET = 'diningapp01';
const REGION = 'us-west-2';
const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: 'AKIAWMQBRPCBEG4AXCQ4',
  secretAccessKey: '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'
};

const ReactS3Client = new S3(config);

const CreateRestaurant = () => {
  const history = useHistory();
  const [getCuisines, setCuisines] = useState([]);
  const [getRestaurantDetails, setRestaurantDetails] = useState({ name: '', tagline: '', email: '', password: '', costforone: '', cuisine: [] });
  const [getAddress, setAddress] = useState({ address: '', lat: 0, lng: 0 });
  const [getImages, setImages] = useState([]);

  useEffect(() => {
    fetchRequiredData();
  }, []);

  const fetchRequiredData = async () => {
    const cuisines = await http.get('/admin/cuisines');
    const sizes = cuisines.data.data.map(value => ({ value: value.id, label: value.name }));
    setCuisines(sizes);
  };

  const uploadFile = async (e) => {
    let file = e.target.files[0];
    let filename = file.name;
    let newFilename = `${(Math.random() * 1000000000000000000)}-${(Math.random() * 1000000000000000000)}.${filename.split('.').pop()}`;
    ReactS3Client.uploadFile(file, newFilename)
      .then((data) => { setImages([...getImages, data.location]) })
      .catch(err => console.error(err));
  };

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === 'seasons') {
      if (checked) {
        setRestaurantDetails({ ...getRestaurantDetails, seasons: [...getRestaurantDetails.seasons, value] });
      } else {
        let currentSeasons = [...getRestaurantDetails.seasons];
        const index = currentSeasons.indexOf(value);
        currentSeasons.splice(index, 1);
        setRestaurantDetails({ ...getRestaurantDetails, seasons: currentSeasons });
      }
    } else if (name === 'Category' || name === 'StaticBrand') {
      setRestaurantDetails({ ...getRestaurantDetails, [name]: JSON.parse(value) });
    } else {
      setRestaurantDetails({ ...getRestaurantDetails, [name]: value });
    }
  };

  const handleSelectChange = (value) => {
    setRestaurantDetails({ ...getRestaurantDetails, cuisine: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const payload = {
      email: getRestaurantDetails.email,
      password: getRestaurantDetails.password,
      name: getRestaurantDetails.name,
      tagline: getRestaurantDetails.tagline,
      address: getAddress.address,
      location: {
        latitude: getAddress.lat,
        longitude: getAddress.lng
      },
      costforone: getRestaurantDetails.costforone,
      images: getImages,
      cuisine: getRestaurantDetails.cuisine.map(value => value.value)
    };

    http.post('admin/restaurant/create', payload)
      .then((res) => {
        if (res.data.Success) {
          toast.success('Great, New restaurant Added');
          history.push('/restaurants');
        } else {
          // console.log(res.data.message);
          toast.error(res.data.message);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message);
        console.error(e.response.data);
      });
  };

  const handleRemoveImage = async (image) => {
    let images = [...getImages];
    const index = images.indexOf(image);
    images.splice(index, 1);
    setImages(images);
  };

  const handleLocation = (e) => {
    const { formatted_address, geometry } = e;
    const lat = geometry.location.lat();
    const lng = geometry.location.lng();
    setAddress({ lat, lng, address: formatted_address });
  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center">
        <div className="start">
          <h4>
            <Link to='/restaurants'>
              <img className="BackArrow" src={LeftArrow} alt="Back" />
            </Link>
            Add Restaurant
          </h4>
        </div>
        <div className="end d-inline-flex">
          <div className="TopButton"></div>
          <Navbar title="Overview" />
        </div>
      </div>

      <Card className="table-wrapper table-responsive shadow-sm ">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-10">
              <div className="bg-light-gray row ">
                <div className="form-group col-6">
                  <label className="form-label-1" htmlFor="restaurantName">Name of the Restaurant</label>
                  <input type="text" required value={getRestaurantDetails.name} name="name" onChange={handleInputChange} className="form-control" placeholder="Enter your Restaurant name" />
                </div>
                <div className="form-group col-6">
                  <label className="form-label-1" htmlFor="tagline">Tagline</label>
                  <input type="text" required name="tagline" value={getRestaurantDetails.tagline} onChange={handleInputChange} className="form-control" placeholder="Enter tagline of your restaurant" />
                </div>
                <div className="form-group col-6">
                  <label className="form-label-1" htmlFor="email">Email</label>
                  <input type="email" required value={getRestaurantDetails.email} name="email" onChange={handleInputChange} className="form-control" placeholder="Enter email here" />
                </div>
                <div className="form-group col-6">
                  <label className="form-label-1" htmlFor="password">Password</label>
                  <input type="password" required value={getRestaurantDetails.password} name="password" onChange={handleInputChange} className="form-control" placeholder="Enter password for your restaurant" />
                </div>
                
                <div className="form-group col-6">
                  <label className="form-label-1" htmlFor="address">Address</label>
                  <Autocomplete
                    required
                    options={{ types: ['establishment', 'geocode'] }}
                    className="form-control"
                    apiKey={'AIzaSyAsNp6rPCXV5UMYyfBCfA27xfeswahJMvM'}
                    onPlaceSelected={handleLocation}
                    placeholder="Search restaurant address"
                  />
                </div>

                
                <div className="form-group col-6">
                  <label className="form-label-1" htmlFor="cuisine">Cuisine</label>
                  <Select placeholder="Please select cuisine" onChange={handleSelectChange} name='cuisine' isMulti options={getCuisines} />
                </div>
                <div className="form-group col-6">
                  <label className="form-label-1" htmlFor="costforone">Cost for One</label>
                  <input type="number" required name="costforone" value={getRestaurantDetails.costforone} onChange={handleInputChange} className="form-control" placeholder="Enter cost for one" />
                </div>
                <div className="form-group col-12">
                  <label className="form-label-1" htmlFor="images">Images</label>
                  <div className="row">
                    <div className="col-2 Input-file">
                      <input type="file" id="files" accept="image/png, image/jpeg" onChange={uploadFile} className="hide-input" />
                      <div className="upload-box">
                        <img className="BackArrow icon" src={Plus} alt="Upload" />
                      </div>
                    </div>
                    {getImages.map(image => (
                      <div className="col-3 image-preview" key={image}>
                        <small onClick={() => handleRemoveImage(image)} className="text-danger">
                          <FontAwesomeIcon icon={faTrash} />
                        </small>
                        <img height='109px' width='149px' src={image} alt="Preview" />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="col-3">
                  <button type="submit" className="btn btn-info mt-4">Add Restaurant</button>
                </div>
              </div>
            </div>

          </div>
        </form>
      </Card>
    </>
  );
};

export default CreateRestaurant;
